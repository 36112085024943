html,body{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif,'Baskervvile','Dancing Script','Montserrat','Josefin Sans';
    margin:0px;
    padding:0px;
}

.footer{
  display: flex;
  position: absolute;
  width:100%;
  margin-top:130px;
  margin-bottom: 0;
  background-color: rgb(96, 73, 139) !important;
  color:white;
  padding:0;
  font-family: "Josefin Sans";
  
 
}
#section1{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin:0 auto;
}

.footer #section1 article{
  display:flex;
  align-self: center;
  flex-direction: column;
  height:100%;
  width:100%;
 align-items: center;
}

.footer #section1 article h3{
  border-bottom:2px solid white;
  margin-bottom:10px;
  padding-bottom:5px;
}

.footer section article ul{
  list-style: none;
  padding-left:0;
  display:flex;
  margin:0;
  align-items: flex-start;
  flex-flow: column;
  justify-content: space-between;


}

.footer section article ul li {
  margin:0 0 10px 0;
  padding:0;
}

.footer section article ul li a{
  color:white;
  text-decoration: none;
}

.footLink{
  text-decoration: none;
  color:white;
  
}


@media only screen and (max-width: 750px){
  #section1{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin:0 auto;
    text-align: center;
  }

  .footer #section1 article{
    display:flex;

    width:100%;
   align-items: center;
  }
  
  .footer section article ul{
    list-style: none;
    display:flex;
    flex-flow: row wrap;
    width:80%;
  
  }
  

}