#storeContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:50px;
    overflow: hidden;
    min-height:600px;
}

#itemContainer{
  display:grid;
  width:100%;
  grid-template-columns: repeat(auto-fit,minmax(100px,300px));
  row-gap:2em;
  column-gap:4em;
  justify-content: center;
  align-self: center;
  justify-items: center;
}


.card{
    background-color: rgb(248, 248, 248) !important;
    height:24em;
    padding:10px;
    width:20em;
}


.cardMedia{
    height:20em;
    border-radius:4px;
}


.storeHeader{
    font-family: 'Dancing Script';
    color:rgb(96, 73, 139);
}

.storeLink{
    color: rgb(103, 147, 71);
    text-decoration: none;
}

.tagContainer{
    display:flex;
    align-items: flex-start;
}

#errorStoreDiv{
    margin:180px auto;
    text-align: center;
    color:rgb(96, 73, 139);
    font-family: "Josefin Sans";
    justify-self: center;
    width:100%;
    grid-column:1/3;
}


@media only screen and (max-width: 700px){
    #itemContainer{
        display:grid;
        width:90%;
        grid-template-columns: repeat(2,minmax(100px,200px));
        row-gap:1em;
        column-gap:2em;
        justify-content: center;
        align-self: center;
        justify-items: space-evenly;
      
      }

      
      .card{
        height:16.5em;
        padding:10px;
        width:100%;
    }
    
    .cardMedia{
        height:11em;
        width:100%;
    }
    
}
