#cartWrapper{
   display: flex;
   flex-direction: row;
   margin:100px auto 0 auto;
   min-height: 570px;
   justify-content: space-evenly;
   text-align: center;
   font-family:'Josefin Sans';
   align-items: flex-start;
   
}

.itemWrapper{
   display: flex;
   flex-direction: column;
}



.cartItem{
   display: grid;
   grid-template-columns: 1fr 3fr 1fr 1fr;
   grid-template-rows:repeat(auto-fill,1fr);
   column-gap: 15px;
   border-bottom-color:rgb(239, 239, 239);
   border-bottom-style:solid;
   border-bottom-width:1px;
   align-items: center;
   width:40em;
   padding-top:30px;
   padding-bottom:15px;
   margin:5px auto;
}

.cartItem img{
   height:120px;
   width:100px;
}

.clear{
   margin-bottom:20px;
   width:100px;
   margin:0px auto 20px auto;
}

.cartRow{
   display: flex;
   flex-direction: column;
  text-align: start;
  
}


.rowTwo p{
   margin:5px 0 0 0 ;
   
}


.rowTwo span{
   font-weight: bold;
}

.rowThree p{
   margin:5px 0 0 0;
}

.remove{
   background:none;
   border:none;
   margin:5px 0 0 0;
   padding:0;
   cursor: pointer;
   width: fit-content;
   color:red;
}




.cartDetails{
   height:400px;
   width:300px;
   display:flex;
   justify-content: space-around;
   flex-direction: column;
   text-align: left;
   background-color: white;
   align-self: flex-start;
    line-height: 45px;

    border-radius: 4px;
}

.cartDetails h3 span{
   float: right;
   position: relative;
   padding-right:10px;
   
}


.cartDetails h3{
   margin-bottom:10px;
   font-size: 1.5em;
}

.listContainer{
   display:flex;
   flex-direction: column;
}

.cartLink{
   color:rgb(103, 147, 71);
   text-decoration: none;
   width: fit-content;
}


#quantityInput{
   display: flex;
   height:20px;
   flex-direction: row;
   align-items: center;
}

#quantityInput button{
   background:none;
   border:none;
   padding:0;
   cursor: pointer;
   color: rgb(96, 73, 139);
   font-size:20px;
}

.quantity{
   margin:5px
}


@media only screen and (max-width: 1000px){
   #cartWrapper{
      display: flex;
      flex-direction: column;
      margin-top:50px;
      height:100%;
      justify-content: center;
      align-items: center;
   }

   .cartItem{
      width:100%;
      
   }


   .cartDetails{
      height:100%;
      justify-content: space-evenly;
      margin: 30px auto 0 auto;
   }
}