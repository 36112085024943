.dashContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-family:'Josefin Sans'
}

.mainContainer{
    display:flex;
    justify-content:space-evenly;
    width:100%;
}

.itemContainer{
    display:flex;
    flex-direction: column;
    overflow-y: auto;
}

.newContainer{
    display:flex;
}

#createForm{
    display: flex;
    flex-direction: column;
    line-height: 25px;
}


@media only screen and (max-width: 1000px){
    .mainContainer{
        display:flex;
        justify-content:center;
        align-items: center;
        width:70%;
        flex-direction: column;
    }

}