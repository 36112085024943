.navContainer{
    display:flex;
    flex-direction: row;
    list-style: none;
    margin:0;
    padding:0;
    color:rgb(96, 73, 139);
    background-color: white;
    top: 0;
    z-index: 100;
    position: fixed;
    width: 100%;
    height:50px;
    align-items: center;
    font-family: 'Josefin Sans';
}

#superTest{
    position: absolute;
    top:50px;
    background-color: white;
    width:100%;
    margin:0;
    padding-left:0;
    left:0;
    height:0;
    overflow:hidden;
    border-bottom: 1px solid rgb(229, 229, 229);
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(auto,50px);
    text-align: center;
}

.bannerText{
    font-size:12px;
    display: flex;
    width:250px;
    height:30px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family:'Dancing Script';
    margin:0 auto;
    padding-bottom:7px;
}

#popper{
    height:fit-content;
    width:300px;
    position: relative;
    z-index: 20;
    padding-top:10px;
    background-color: white;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    font-family: 'Josefin Sans';
}

#expand{
    font-size:15px;
    margin-top:5px;
    padding-bottom:7px;
}

.storeNavLink{
    cursor:pointer;
    color:rgb(103, 147, 71);
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    text-align: center;
    font-size:1em;
}


.navList {
    display:flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-around;
    width: 100%;
    margin:0;
    align-items: center;
}


.link{
    color:rgb(103, 147, 71);
    text-decoration: none;
    margin:0;
}

.fa {
    text-align: center;
    text-decoration: none;
    color:rgb(103, 147, 71);
    border:rgb(103, 147, 71);
    margin:0 auto;
    width:23px;
    height:20px;
    padding-top:3px;
    padding-right: 2px;
  }

  .fa:hover {
    opacity: 0.7;

  }
  

  .fa-facebook {
    color:white; 
    background-color: rgb(103, 147, 71);
    border-radius: 100%;
    text-align: center;
  }
  


@media only screen and (max-width: 700px){
    .navList{
        display:none;
    }

    .navContainer{
        justify-content: space-around;
        
    }

    .bannerText{
        display: flex;
        justify-content: flex-start;
        width:88%;
    }
}