#loading{
 display:flex;
 justify-content: center;
 text-align: center;
 flex-direction: column;

 align-items: center;
 color:rgb(103, 147, 71);
 width:300px;
 margin:150px auto;
}


.circle {
	--circle-len: 225px;
	margin:0 auto;
	text-align: center;
    fill: none;
	stroke: rgb(103, 147, 71);
	stroke-width: 10px;
	stroke-dasharray: var(--circle-len);
	stroke-dashoffset: var(--circle-len);
    animation: draw 1s linear infinite;
}

@keyframes draw {
	to { stroke-dashoffset: 0px; }
}